import React from "react";
import { graphql } from "gatsby";
import {Helmet} from "react-helmet";

import Ad from "../components/ads/Ad"
import DefaultLayout from "../components/layouts/default";
import Header from "../components/layouts/index/Header";
import Wife from "../components/wife/Wife";

import transformWives from "../utils/transform-wife";

export default class Wives extends React.Component {
    render() {
        let housewives = transformWives(this.props.data.realTaglines.housewives);

        let blurb = "Every Real Housewife, ranked by average tagline score.";

        let maxAds = Math.min(housewives.length / 10, 10);
        let adInterval = housewives.length / maxAds;

        return (
            <DefaultLayout adCount={Math.min(housewives.length / 12, 10)}>
                <Helmet>
                    <title>{ 'Every Real Housewive Tagline ranked!!' }</title>
                </Helmet>
                <Header blurb={ blurb } experience="wives" />
                { housewives.map(({ node }, index) => {
                    let ad = ""
                    if (index % adInterval === 0 && index !== housewives.length) {
                        ad = <Ad></Ad>;
                    }

                    return <React.Fragment key={ node.rank }><Wife {...node}/>{ad}</React.Fragment>
                }) }
                <Ad></Ad>
            </DefaultLayout>
        );
    }
};

export const query = graphql`
    query {
        realTaglines {
            housewives {
                edges {
                    node {
                        firstName
                        fullName
                        rank
                        slug
                        housewifeimageSet {
                            crops {
                                height
                                name
                                url
                                width
                            }
                        }
                        seasonList:seasons(first:1) {
                            edges {
                                node {
                                    franchise {
                                        slug
                                        city
                                    }
                                }
                            }
                        }
                        taglineSet(first:1, published: true) {
                            pageInfo {
                                startCursor
                            }
                            edges {
                                node {
                                    tagline
                                    rank
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`