export default function(wives) {
    wives.edges = wives.edges.filter(({ node }) => ( node.taglineSet.edges.length > 0 ));

    wives.edges.map(
        (edge) => {
            // flatten seasons
            let node = edge.node;
            
            node.season = (node.seasonList.edges && node.seasonList.edges.length) ? node.seasonList.edges[0].node : node.season;
            node.franchise = node.season.franchise;
            node.tagline = node.taglineSet.edges[0].node;

            // hw images
            node.image = null;
            if(node.housewifeimageSet.length) {
                let crops = node.housewifeimageSet[0].crops;

                for(let i=0; i<crops.length; i++) {
                    if(crops[i].name === 'square_medium') {
                        node.image = crops[i].url;
                    }
                }
            }

            return edge;
        });
    
    return wives.edges;
}