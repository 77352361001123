import React from "react";

import Logo from "../../logo/Logo";

import "./Header.scss";

export default ( props ) => {
    return (
        <div className="index-header-holder hw-color-bg-light--brand">
            <div className="index-header-logo-holder">
                <Logo />
                <div className="index-header-icon">
                    { props.headerIcon ? props.headerIcon : '' }
                </div>
            </div>
            { props.blurb 
                ? <div className="index-header-description" dangerouslySetInnerHTML={ { __html: props.blurb } }></div> 
                : '' }
            { props.children }
        </div>
    );
};