import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import "./Wife.scss";

export default class Wife extends React.PureComponent {
    constructor(props) {
        super(props);

        this.className = this.createClassName(props);
    }

    createClassName(props) {
        let image = props.image,
            franchise = props.franchise,
            className = `housewife-container housewife-container--${franchise.slug} hw-color-bg-light--${franchise.slug}`;

        if(image == null || !this.props.showImage) {
            className += ` tagline-container--no-image`;
        }
        
        return className;
    }

    render() {
        return (
            <div id={ 'rank_' + this.props.rank } className={ this.className }>
                <div className={ 'housewife-rank hw-color-bg--' + this.props.franchise.slug }>
                    { this.props.rank }
                </div>
                { this.props.showImage && this.props.image != null && (
                    <div className="housewife-image-holder">
                        <img src={ this.props.image } className="housewife-image" alt={ `${this.props.tagline} - ${this.props.fullName}` }/>
                    </div>
                )}
                <div className="housewife-name-tagline-holder">
                    <div className="housewife-name-holder">
                        <Link to={ "/wife/" + this.props.slug }>
                            <h3 className="housewife-name">
                                { this.props.fullName }
                            </h3>
                        </Link>
                    </div>
                    <div className="housewife-top-tagline">
                        <h4 className="housewife-top-tagline-intro">Top Tagline:</h4>
                        <div className="housewife-top-tagline-text">
                            { this.props.tagline.tagline }
                        </div>
                    </div>
                </div>
            </div>
            );
    }
}

Wife.defaultProps = {
    showImage: true
}

Wife.propTypes = {
    image: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    rank: PropTypes.number.isRequired,
    franchise: PropTypes.shape({
        city: PropTypes.string.isRequired,
        acronym: PropTypes.string,
        slug: PropTypes.string
    }),
    tagline: PropTypes.shape({
        tagline: PropTypes.string.isRequired,
        rank: PropTypes.number,
        season: PropTypes.shape({
            number: PropTypes.number
        })
    })
}